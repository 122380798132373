import React from 'react';
import './App.css';
import { LockClosedIcon } from '@heroicons/react/20/solid'
import Header from './Header';
import Footer from './Footer';
import PageHeading from './PageHeading';
import User from './User'
import CognitoClient from './Auth/CognitoClient';

let AmazonCognitoIdentity = require('amazon-cognito-identity-js');

interface Properties {
  setUser: any;
}

interface State {
  failure: boolean;
  pending: boolean;
}

class LoginForm extends React.Component<Properties, State> {
  username: string = '';
  password: string = '';

  constructor(props: any) {
    super(props);

    this.state = {
      failure: false,
      pending: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handleUsernameChange(event: any) {
    this.username = event.target.value;
  }

  handlePasswordChange(event: any) {
    this.password = event.target.value;
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const setUser = this.props.setUser;
    const setState = this.setState.bind(this);

    setState({failure: false});

    const credentials = {
      Username: this.username.toLowerCase(),
      Password: this.password,
    };

    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      credentials
    );

   const client = new CognitoClient();

   let successCallback = (user?: User) => {
    setState(prevState => {
      const merge: Partial<State> = { pending: false };
      return { ...prevState, ...merge };
    });

    if (user) {
      return setUser(user);
    }
    setState({pending: false, failure: false});
   };

   let failureCallback = () => {
    setState({pending: false, failure: true});
   }

   client.authenticate(authenticationDetails, successCallback, failureCallback);
  }

  render() {
    return(
      <div className="relative bg-white">
        <Header />

        <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
          <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="mx-auto max-w-lg">
              <PageHeading text="Bitte melden Sie sich an." />

              <p className="mt-3 text-lg leading-6 text-gray-900">
                Für das Melden einer Störung ist es erforderlich, dass Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort anmelden.
              </p>
              <p className="mt-3 text-base text-gray-500">
                Bei Fragen wenden Sie sich bitte an die Ihnen bekannten Ansprechpartner.
              </p>
            </div>
          </div>
          <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="mx-auto max-w-lg lg:max-w-none">
              <form className="grid grid-cols-1 gap-y-6" onSubmit={this.handleSubmit}>
                {this.state.failure ?
                <p className="rounded-md border-gray-300 px-4 py-3 text-white bg-red-500">
                  <span className="font-bold">Fehler bei der Anmeldung.</span> Bitte überprüfen Sie Ihre Zugangsdaten.
                </p>
                : null }
                <div>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-bauerkirch focus:ring-bauerkirch"
                    placeholder="E-Mail-Adresse"
                    readOnly={this.state.pending}
                    onChange={this.handleUsernameChange}
                  />
                </div>
                <div>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-bauerkirch focus:ring-bauerkirch"
                    placeholder="Passwort"
                    readOnly={this.state.pending}
                    onChange={this.handlePasswordChange}
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-bauerkirch py-3 px-6 text-sm font-medium text-white hover:bg-bauerkirch-darker focus:outline-none focus:ring-1 focus:ring-bauerkirch-darker focus:ring-offset-1"
                  >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <LockClosedIcon className="h-5 w-5 text-white-500 group-hover:text-white-400" aria-hidden="true" />
                    </span>
                    Anmelden
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default LoginForm;
