import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <div className="px-8 py-4 text-sm lg:text-base text-center flex flex-wrap justify-center mx-auto max-w-7xl">
        <a href="https://www.bauer-kirch.de/kontakt" target="_blank" rel="noreferrer" className="inline-block m-1 px-2 py-1 hover:text-bauerkirch">
            Kontakt
        </a>
        <a href="https://www.bauer-kirch.de/datenschutz" target="_blank" rel="noreferrer" className="inline-block m-1 px-2 py-1 hover:text-bauerkirch">
            Datenschutz
        </a>

        <a href="https://www.bauer-kirch.de/impressum" target="_blank" rel="noreferrer" className="inline-block m-1 px-2 py-1 hover:text-bauerkirch">
            Impressum
        </a>
      </div>
    )
  }
}

export default Footer;
