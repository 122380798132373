import React from 'react';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import Login from './Login';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import PageHeading from './PageHeading';
import User from './User'
import IncidentForm from './IncidentForm'

import CognitoClient from './Auth/CognitoClient';

interface State {
  loggedIn: boolean;
  token?: string;
  user?: User;
}

class App extends React.Component<{}, State> {
  
  constructor(props: any) {
    super(props);

    this.state = {
      loggedIn: false,
    };
  }

  setState<K extends keyof State>(state: State | ((prevState: Readonly<State>, props: Readonly<{}>) => State | Pick<State, K> | null) | Pick<State, K> | null, callback?: (() => void) | undefined): void {
    window.localStorage.setItem('state', JSON.stringify(state));
    super.setState(state)
  }

  setToken = (token: string) => {
    return this.setState({...this.state, token: token, loggedIn: true});
  }

  setUser = (user: User) => {
    return this.setState({...this.state, user: user});
  }

  setLoggedIn = (loggedIn: boolean) => {
    return this.setState({...this.state, loggedIn: loggedIn});
  }

  componentDidMount(): void {
    let client = new CognitoClient();

    client.getCurrentUser((user?: User) => {
      if (user) {
        this.setUser(user);
      }
    });
  }

  render() {
    if (!this.state.user) {
      return <Login setUser={this.setUser} />
    }
  
    return(
      <div className="relative bg-white">
        <Header />

        <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
          <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="mx-auto max-w-lg">
              <PageHeading text="Störung melden" />
              <p className="mt-3 text-lg leading-6 text-gray-900">
                Wenn Sie eine Betriebsstörung in Ihrem Projekt festgestellt haben, können Sie hier eine Störungsmeldung erstellen.
              </p>
              <p className="mt-3 text-base text-gray-500">
                <span className="font-bold">Zusätzlich</span> können Sie uns
                {this.state.user.noc_phone ?
                  " telefonisch oder "
                : ''} per E-Mail erreichen. Die Störungsmeldung <span className="font-bold">muss</span> jedoch zwingend über das Formular erfolgen, um eine zeitnahe Entstörung zu ermöglichen.
              </p>
              <dl className="mt-8 text-base text-gray-500">
                {this.state.user.noc_phone ?
                <div className="mt-6">
                  <dd className="flex">
                    <PhoneIcon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <a href="tel:+4924194223013" className="ml-3">+49-241-94223013</a>
                  </dd>
                </div>
                : null}
                <div className="mt-3">
                  <dd className="flex">
                    <EnvelopeIcon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <a href="mailto:incident-response@bauer-kirch.de" className="ml-3">incident-response@bauer-kirch.de</a>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="mx-auto max-w-lg lg:max-w-none">
              <IncidentForm user={this.state.user} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default App;
