import React from 'react';
import './App.css';
import TextInput from './Form/TextInput';
import User from './User'

interface State {
  failure: boolean;
  submitted: boolean;
  pending: boolean;
}

class IncidentForm extends React.Component<{user: User}, State> {
  description: string = '';

  constructor(props: any) {
    super(props);
  
    this.state = {
      failure: false,
      pending: false,
      submitted: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
  }

  handleDescriptionChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.description = event.target.value;
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
  
    const setState = this.setState.bind(this);

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.props.user.token,
      },
      body: JSON.stringify({
        user: this.props.user,
        description: this.description,
      })
    };

    const url = process.env.REACT_APP_BACKEND_URL || '';

    fetch(url, options)
      .then(response => response.json())
      .then(data => {
        setState({pending: false, failure: false, submitted: true});
      })
      .catch(() => {
        setState({pending: false, failure: true});
      });
  }

  render() {
    return(
      <form onSubmit={this.handleSubmit} className="grid grid-cols-1 gap-y-6">
        {this.state.failure ?
          <p className="rounded-md border-gray-300 px-4 py-3 text-white bg-red-500">
            <span className="font-bold">Fehler bei der Übermittlung.</span> Bitte überprüfen sie ihre Eingaben.
          </p>
        : null }
        {this.state.submitted ?
          <p className="rounded-md border-gray-300 px-4 py-3 text-white bg-green-500">
            <span className="font-bold">Übermittlung erfolgreich.</span> Die Betriebsstörung wurde erfolgreich übermittelt.
          </p>
        : null }
        <div>
          <label htmlFor="name" className="sr-only">
            Ihr Name
          </label>
          <TextInput
            name="name"
            placeholder="Vor- und Nachname"
            value={this.props.user.name}
            readonly={true}
          />
        </div>
        <div>
          <label htmlFor="company" className="sr-only">
            Firma
          </label>
          <TextInput
            name="company"
            placeholder="Firma"
            value={this.props.user.customer}
            readonly={true}
          />
        </div>
        <div>
          <label htmlFor="email" className="sr-only">
            E-Mail-Adresse
          </label>
          <TextInput
            name="email"
            placeholder="E-Mail-Adresse"
            value={this.props.user.email}
            readonly={true}
          />
        </div>
        <div>
          <label htmlFor="phone" className="sr-only">
            Telefonnummer für Rückfragen
          </label>
          <TextInput
            name="phone"
            placeholder="Telefonnummer für Rückfragen"
            value={this.props.user.phone}
            readonly={true}
          />
        </div>
        <div>
          <label htmlFor="message" className="sr-only">
            Beschreibung der Störung
          </label>
          <textarea
            id="message"
            name="message"
            rows={4}
            className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-bauerkirch focus:ring-bauerkirch"
            placeholder="Möglichst detaillierte Beschreibung der Störung"
            defaultValue={''}
            onChange={this.handleDescriptionChange}
            readOnly={this.state.pending}
            required={true}
          />
        </div>
        <div>
          <input
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-bauerkirch py-3 px-6 text-base font-medium text-white shadow-sm hover:bg-bauerkirch-darker focus:outline-none focus:ring-1 focus:ring-bauerkirch-darker focus:ring-offset-1"
            value="Absenden"
          / >
        </div>
      </form>
    )
  }
}

export default IncidentForm;
