import React from 'react';

interface Properties {
  text: string;
}

class PageHeading extends React.Component<Properties, {}> {
  render() {
    return(
      <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl">
        {this.props.text}
      </h2>
    )
  }
}

export default PageHeading;
