import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import User from '../User';

export type UserCallback = (user?: User) => void;

class CognitoClient {
  userPool: CognitoUserPool;

  constructor() {
    this.userPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
      ClientId: process.env.REACT_APP_CLIENT_ID || '',
    });
  }

  authenticate = (credentials: AuthenticationDetails, success: UserCallback, failure: any) => {
    const userData = {
	    Username: credentials.getUsername(),
	    Pool: this.userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    const _getUser = this.getUser;

    cognitoUser.authenticateUser(credentials, {
      onSuccess: function(result: CognitoUserSession) {
        if (!result.isValid) {
          return failure();
        }

        _getUser(cognitoUser, (user?: User) => {
          if (user) {
            return success(user);
          }

          failure();
        });
      },
    
      onFailure: function(err: any) {
        return failure();
      },
    });
  };

  getCurrentUser = (callback: UserCallback) => {
    let currentUser = this.userPool.getCurrentUser();
    if (!currentUser) {
      callback();
      return;
    }

    this.getUser(currentUser, callback);
  };

  getUser = (cognitoUser: CognitoUser, callback: UserCallback) => {
    cognitoUser.getSession((error: null, session: CognitoUserSession) => {
      if (error || !session.isValid()) {
        return callback();
      }

      cognitoUser.getUserAttributes((err?: Error, attributes?: CognitoUserAttribute[]) => {
        if (err) {
          callback();
        }
  
        let user: User = {
          token: '',
        };

        user.token = session.getIdToken().getJwtToken();

        if (attributes) {
          for (let i = 0; i < attributes?.length; i++) {
            if (attributes[i].getName() === 'name') {
              user.name = attributes[i].getValue();
            }
            if (attributes[i].getName() === 'email') {
              user.email = attributes[i].getValue();
            }
            if (attributes[i].getName() === 'custom:customer') {
              user.customer = attributes[i].getValue();
            }
            if (attributes[i].getName() === 'custom:noc_phone') {
              user.noc_phone = 'true' === attributes[i].getValue();
            }
            if (attributes[i].getName() === 'phone_number') {
              user.phone = attributes[i].getValue();
            }
          }
        }
  
        callback(user);
      });
    });
  };
}

export default CognitoClient;
