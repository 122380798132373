import React from 'react';

interface Properties {
  name: string;
  placeholder: string;
  value: string;
  readonly: boolean;
}

class TextInput extends React.Component<Properties, {}> {
  public static defaultProps = {
    placeholder: '',
    value: '',
    readonly: false,
  };

  render() {
    return(
    <input
        type="text"
        name={this.props.name}
        id={this.props.name}
        className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-bauerkirch focus:ring-bauerkirch"
        placeholder={this.props.placeholder}
        readOnly={this.props.readonly}
        value={this.props.value}
      />

    )
  }
}

export default TextInput;
